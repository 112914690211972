@font-face {
  font-family: 'MTS Sans Regular';
  src: url("./assets/fonts/MTSSans-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: 'MTS Sans Medium';
    src: url("./assets/fonts/MTSSans-Medium.otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: url('./assets/fonts/MontserratAlternates-Regular.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url('./assets/fonts/MontserratAlternates-Medium.ttf');
    font-weight: 700;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 16px;
}

body {
    height: 100%;
    min-height: 100%;
    font-family: 'MTS Sans Regular', Arial, sans-serif;
}

label, button, a, *:focus {
    -webkit-tap-highlight-color: transparent;
    appearance: none;
}

#root {
    height: 100%;
    min-height: 100%;
    background-color: #686868;
    overflow: hidden;
}

/* For remove auto-inserting iframe by react-scripts, don't forget comment this before release */
/*iframe {*/
/*    display: none;*/
/*}*/